import Vue from 'vue'
import VueRouter from 'vue-router'
import PageNotFound from './pages/PageNotFound.vue'
import Home from './pages/Home.vue'
import CsGoProducts from './pages/CsGoProducts.vue'
import NewArrivals from './pages/NewArrivals.vue'
import Sale from './pages/Sale.vue'
import Recommended from './pages/Recommended.vue'
import Cart from './pages/Cart.vue'
import Profile from './pages/Profile.vue'
import SellOffers from './pages/SellOffers.vue'
import ProductPage from './pages/ProductPage.vue'
import PaymentSuccessPage from './pages/PaymentSuccessPage.vue'
import PaymentFaliPage from './pages/PaymentFaliPage.vue'
import TextPage from './pages/TextPage.vue'





Vue.use(VueRouter)

export default new VueRouter({
	routes: [
		{ path: '/', redirect: '/home' },
		{
			path: '/home',
			name: 'Home',
			component: Home
		},
		{
			path: '/products',
			name: 'CsGoProducts',
			component: CsGoProducts
		},
		{
			path: '/new-arrivals',
			name: 'NewArrivals',
			component: NewArrivals
		},
		{
			path: '/sale',
			name: 'Sale',
			component: Sale
		},
		{
			path: '/recommended',
			name: 'Recommended',
			component: Recommended
		},
		{
			path: '/cart',
			name: 'Cart',
			component: Cart
		},
		{
			path: '/profile',
			name: 'Profile',
			component: Profile
		},
		{
			path: '/sell-offers',
			name: 'SellOffers',
			component: SellOffers
		},
		{
			path: '/products/:id',
			name: 'ProductPage',
			component: ProductPage
		},
		{
			path: '/success-payment',
			name: 'PaymentSuccessPage',
			component: PaymentSuccessPage
		},
		{
			path: '/fail-payment',
			name: 'PaymentFaliPage',
			component: PaymentFaliPage
		},
		{
			path: '/pages/:id',
			name: 'TextPage',
			component: TextPage
		},
		{ 
			path: "*", 
			name: 'PageNotFound',
			component: PageNotFound,
			props: {title: "PageNotFound"}
		}
	]
})

