<template>
	<div class="menu">
		<ul class="nav">
			<template v-for="(item, i) in $parent.nav">
			<li class="nav__item" :key="i" v-if="item.link != '/privacy' && item.link != '/terms'">
				<router-link :to="item.link">
				<span v-if="item.title == 'New Arrivals'">{{ $t('New') }}</span> 
				<template v-if="item.title == 'New Arrivals'">
					{{ $t('Arrivals') }}
				</template>
				<template v-else>
					{{$t(item.title) != $t('Sale')? $t(item.title) : ''}}
				</template>
				<span class="red" v-if="item.title == 'Sale'">{{ $('Sale') }}</span>
				</router-link>
			</li>
			</template>
			<div class="nav__item" v-if="$route.name == 'Profile'  || $route.name == 'SellOffers'">
			<a @click="$emit('openSaleSkinModal')">{{ $t('Sale your skin') }}</a>
			</div>
		</ul>
	</div>
</template>

<script>
/*import Vue from 'vue';
var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)*/
export default {
	name: 'Menu',
	props: [],
	data: function() {
		return {
			
		}
	},
	methods: {
		
	},
}
</script>
