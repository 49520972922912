<template>
  <main class="main csgo-products-page page-inside">
    <div class="main-wrapper">
      <section class="section hero-section sale-section product-list-section">
        <div class="wrapper">
          <router-link to="/" class="back">
            <img src="./../assets/back.svg" class="img"/>
            <span class="desc">{{ $t('Back') }}</span>
          </router-link>
          <div class="title big">{{ $t('Recommended') }}</div>
          <div class="list products sale-products">
            <div class="item" v-for="(item, index) in $parent.recommendedProducts" :key="index">
              <div class="item-wrapper">
                <div class="desc">{{item.category}}</div>
                <a @click="$parent.goToProduct(item)" class="title">{{item.title}}</a>
                <a @click="$parent.goToProduct(item)" class="preview">
                  <img :src="imgDomain + item.img_url" class="img"/>
                  <transition name="fade">
                    <span class="title" v-if="$parent.addToCartChosenItem == item.id">{{ $t('Added') }}</span>
                  </transition>
                </a>
                <div class="price title">
                  <span class="discount" v-if="item.old_price != '0.00'">{{item.old_price}} {{$parent.currency}}</span>
                  <span>{{item.price}} {{$parent.currency}}</span>
                </div>
                <a class="link" @click="$parent.addToCart(item, item.id)">
                  <span>{{ $t('Add to') }}</span>
                  <svg id="shopping-cart_1_" data-name="shopping-cart (1)" xmlns="http://www.w3.org/2000/svg" width="19.367" height="18.119" viewBox="0 0 19.367 18.119">
                    <path id="Контур_3" data-name="Контур 3" d="M50.367,94.873H35.607L34.745,91H31v1.291h2.71l2.462,11.125h-.008a3.109,3.109,0,0,0,1.607,2.712,1.942,1.942,0,1,0,3.439.408h4.111a1.919,1.919,0,0,0-.119.646,1.937,1.937,0,1,0,1.937-1.937H39.285a1.829,1.829,0,0,1-1.778-1.417l12.861-2.573Z" transform="translate(-31 -91)" fill="#8d8e92"/>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>
<script>

export default {
  name: 'Recommended',
  props: [],
  components: {
  },
  data: function() {
    return {
      imgDomain: ''
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
  }
}
</script>