<template>
  <main class="main csgo-products-page product-page page-inside">
    <div class="main-wrapper">
      <section class="section hero-section product-list-section">
        <div class="wrapper">
          <router-link to="/" class="back">
            <img src="./../assets/back.svg" class="img"/>
            <span class="desc">{{ $t('Back') }}</span>
          </router-link>
          <div class="title big">{{ $t('CS:GO Products') }}</div>
          <transition name="fade">
            <div class="product-preview" v-if="$parent.productPageItem">
              <div class="preview-container">
                <div class="preview">
                  <transition name="fade">
                    <span class="title" v-if="$parent.addToCartChosenItem == $parent.productPageItem.id">{{ $t('Added') }}</span>
                  </transition>
                  <img :src="$parent.imgDomain + $parent.productPageItem.img_url" class="img"/>
                </div>
              </div>
              <div class="text">
                <div class="title">{{$parent.productPageItem.title}}</div>
                <div class="row">
                  <div class="desc">{{ $t('Category:') }}</div>
                  <div class="desc">{{$parent.productPageItem.type}}</div>
                </div>
                <div class="row">
                  <div class="desc">{{ $t('Quality:') }}</div>
                  <div class="desc">{{$parent.productPageItem.quality}}</div>
                </div>
                <div class="row row-price">
                  <div class="desc">{{ $t('Price:') }}</div>
                  <div class="desc">{{$parent.productPageItem.price}} {{currency}}</div>
                </div>
                <button class="button long" @click="$parent.addToCart($parent.productPageItem, $parent.productPageItem.id)">
                  <span>{{ $t('Add to') }}</span>
                  <svg id="shopping-cart_1_" data-name="shopping-cart (1)" xmlns="http://www.w3.org/2000/svg" width="19.367" height="18.119" viewBox="0 0 19.367 18.119">
                    <path id="Контур_3" data-name="Контур 3" d="M50.367,94.873H35.607L34.745,91H31v1.291h2.71l2.462,11.125h-.008a3.109,3.109,0,0,0,1.607,2.712,1.942,1.942,0,1,0,3.439.408h4.111a1.919,1.919,0,0,0-.119.646,1.937,1.937,0,1,0,1.937-1.937H39.285a1.829,1.829,0,0,1-1.778-1.417l12.861-2.573Z" transform="translate(-31 -91)" fill="#fff"/>
                  </svg>
                </button>
              </div>
            </div>
          </transition>
        </div>
      </section>
      <div class="section features-section">
        <ul class="list">
          <li class="item">
            <div class="icon">
              <img src="./../assets/shield.svg" class="img"/>
            </div>
            <div class="title">{{$t('100% Secure')}}</div>
            <div class="desc">{{ $t('All transactions are thoroughly monitored, so your money and skins are safe.') }}</div>
          </li>
          <li class="item">
            <div class="icon">
              <img src="./../assets/layer.svg" class="img"/>
            </div>
            <div class="title">{{ $t('Wide Choice') }}</div>
            <div class="desc">{{ $t('There are many variations of unique and high quality skins.') }}</div>
          </li>
          <li class="item">
            <div class="icon">
              <img src="./../assets/discount.svg" class="img"/>
            </div>
            <div class="title">{{ $t('Best Prices') }}</div>
            <div class="desc">{{ $t('CS isn\'t about flash grenades and headshots, it\'s about looking beautiful. Cheap, fast, safe and 24/7.') }}</div>
          </li>
        </ul>
      </div>
      <div class="section arrivals-section recommended-section">
        <div class="wrapper">
          <div class="top">
            <div class="title">
              {{ $t('Similar Products') }}
            </div>
          </div>
          <SlickItem v-if="$parent.similarProducts.length" :list="$parent.similarProducts" :tag="$t('We Recommend')" />
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import SlickItem from '../components/SlickItem.vue'
export default {
  name: 'ProductPage',
  props: ['addToCartChosenItem', 'currency'],
  components: {
    SlickItem
  },
  data: function() {
    return {
      
    }
  },
  created() {
  },
  mounted() {
    
    this.$parent.getProductPageContent(this.$route.params.id);
  },
  methods: {
    addToCart(item, id) {
      this.$parent.addToCart(item, id);
    },
    goToProduct(item) {
      this.$parent.goToProduct(item);
    }
  }
}
</script>