<template>
    <div class="cookie-modal" v-if="!showCookieModal">
      <div class="cookie-content">
        <span v-if="manageIsVisible">
          <div class="title samll">
            {{ $t('Customize Consent Preferences') }}
          </div>
          <div class="desc">
            {{ $t('We use cookies to help you navigate efficiently and perform certain functions. You will find detailed information about all cookies under each consent category below.') }}
          </div>
          <div class="desc">
            {{ $t('The cookies that are categorized as "Necessary" are stored on your browser as they are essential for enabling the basic functionalities of the site.') }}
          </div>
          <div class="desc">
            {{ $t('We also use third-party cookies that help us analyze how you use this website, store your preferences, and provide the content and advertisements that are relevant to you. These cookies will only be stored in your browser with your prior consent.') }}
          </div>
          <div class="desc">
            {{ $t('You can choose to enable or disable some or all of these cookies but disabling some of them may affect your browsing experience.') }}
          </div>
          <div class="chekbox-container">
            <label class="chekbox-label">
              <div class="chekbox-label-wrapper">
                <input type="checkbox" disabled v-model="necessary"/>
                <div class="checkbox"></div>
                <div class="title small">{{ $t('Necessary') }}</div>
                <div class="desc">{{ $t('Necessary cookies are required to enable the basic features of this site, such as providing secure log-in or adjusting your consent preferences. These cookies do not store any personally identifiable data.') }}</div>
              </div>
            </label>
          </div>
          <div class="chekbox-container">
            <label class="chekbox-label">
              <div class="chekbox-label-wrapper">
                <input type="checkbox" v-model="functional"/>
                <div class="checkbox"></div>
                <div class="title small">{{ $t('Functional') }}</div>
                <div class="desc">
                  {{ $t('Functional cookies help perform certain functionalities like sharing the content of the website on social media platforms, collecting feedback, and other third-party features.') }}
                </div>
              </div>
            </label>
          </div>
          <div class="chekbox-container">
            <label class="chekbox-label">
              <div class="chekbox-label-wrapper">
                <input type="checkbox" v-model="analytics"/>
                <div class="checkbox"></div>
                <div class="title small">
                  {{ $t('Analytics') }}
                </div>
                <div class="desc">
                  {{ $t('Analytical cookies are used to understand how visitors interact with the website. These cookies help provide information on metrics such as the number of visitors, bounce rate, traffic source, etc.') }}
                </div>
              </div>
            </label>
          </div>
          <div class="chekbox-container">
            <label class="chekbox-label">
              <div class="chekbox-label-wrapper">
                <input type="checkbox" v-model="performance"/>
                <div class="checkbox"></div>
                <div class="title small">
                  {{ $t('Performance') }}
                </div>
                <div class="desc">
                  {{ $t('Performance cookies are used to understand and analyze the key performance indexes of the website which helps in delivering a better user experience for the visitors.') }}
                </div>
              </div>
            </label>
          </div>
          <div class="chekbox-container">
            <label class="chekbox-label">
              <div class="chekbox-label-wrapper">
                <input type="checkbox" v-model="advertisement"/>
                <div class="checkbox"></div>
                <div class="title small">
                  {{ $t('Advertisement') }}
                </div>
                <div class="desc">
                  {{ $t('Advertisement cookies are used to provide visitors with customized advertisements based on the pages you visited previously and to analyze the effectiveness of the ad campaigns.') }}
                </div>
              </div>
            </label>
          </div>
          
          <button class="button" @click="acceptCookies">
            <span>{{ $t('Accept All') }}</span>
          </button>
          <button @click="acceptCookies" class="button">
            <span>{{ $t('Save My Preferences') }}</span>
          </button>
          <button @click="declineCookies" class="button gray manage-button">
            <span>{{$t('Reject All')}}</span>
          </button>
        </span>
        <span v-else>
          <div class="title small">
            {{ $t('Cookies on This Site') }}
          </div>
          <p class="desc" v-if="$parent.textPageList && $parent.textPageList.filter(item => item.title.toLowerCase().includes('cookie'))[0]">
            {{ $t('We use cookies to improve your experience and ensure our website functions correctly. Essential cookies are always active as they are crucial for core features. Additionally, we use cookies for other purposes, which you can manage according to your preferences. For more details, please review our ') }}
            <a @click="$parent.goToPage('cookie')" class="desc link">{{$parent.textPageList.filter(item => item.title.toLowerCase().includes('cookie'))[0].title}}</a>
          </p>
          <button class="button" @click="acceptCookies">
            <span>{{ $t('Accept All') }}</span>
          </button>
          <button @click="declineCookies" class="button gray decline-button">
            <span>{{ $t('Reject All') }}</span>
          </button>
          <button @click="manageCookies" class="button gray manage-button">
            <span>{{ $t('Customize') }}</span>
          </button>
        </span>
      </div>
    </div>
  </template>
  
  <script>
  import VueCookies from 'vue-js-cookie';
  
  export default {
    data() {
      return {
        showCookieModal: VueCookies.get('cookiesAccepted'), // Check if user accepted cookies
        manageIsVisible: false,
        necessary: true,
        analytics: false,
        functional: false,
        performance: false, 
        advertisement: false
      };
    },
    methods: {
      acceptCookies() {
        this.showCookieModal = true;
        VueCookies.set('cookiesAccepted', 'true', 365); // Set a cookie that lasts for a year
      },
      declineCookies() {
        this.showCookieModal = true;
        VueCookies.set('cookiesAccepted', 'false', 365);
      },
      manageCookies() {
        this.manageIsVisible = true;
      }
    },
  };
  </script>
  <style scoped>
  .cookie-modal {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 700px;
    background-color: #13151D;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    z-index: 3;
    overflow: auto;
    max-height: calc(100vh - 40px);
  }
  .cookie-modal .title.samll {
    font-size: 24px;
    line-height: 32px;
  }
  .cookie-modal .desc {
    color: #fff;
    margin-top: 16px;
    font-size: 14px;
    line-height: 18px;
  }
  .cookie-modal .desc a {
    text-decoration: underline;
  }
  .button {
    margin: 16px 16px 0;
    width: 100px;
    display: inline-block;
    color: #fff;
    padding: 12px 32px;
    font-size: 16px;
  }
  .cookie-content {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .cookie-content .button {
    width: 190px;
    height: 52px;
    padding: 0;
  }
  .cookie-content .chekbox-label .title.small {
    color: #fff;
    font-size: 18px;
    line-height: 24px;
  }
  .cookie-content .chekbox-container {
    margin-top: 24px;
  }
  .cookie-content .chekbox-container + .chekbox-container {
    margin-top: 16px;
  }
  .accept-button {
    background-color: #007BFF;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .accept-button:hover {
    background-color: #0056b3;
  }
  </style>