<template>
	<footer class="footer">
      <div class="footer-section">
        <div class="wrapper">
          <div class="left">
            <a href="index.html" class="logo">
              <img src="./../assets/logo-mini.svg" class="img"/>
            </a>
            <div class="text">
              <div class="desc" v-if="$parent.footerRequisites">{{$parent.footerRequisites}}</div>
              <div class="desc">
                {{ $t('The Cardholder himself is responsible for compliance with the rules of the copyright holder.') }}
                <br/>
                {{ $t('Any trademarks used belong to their respective owners.') }}
              </div>
            </div>
          </div>
          <div class="right">
            <ul class="nav">
              <template  v-for="(item, i) in $parent.nav">
                <li class="nav__item" :key="'k' + i" v-if="item.title != 'Home'">
                  <router-link :to="item.link">
                    {{$t(item.title)}}
                  </router-link>
                </li>
              </template>
              <template  v-for="item in $parent.textPageList">
                <li class="nav__item" :key="item.id">
                  <a @click="$parent.goToPage(item)">
                    {{$t(item.title)}}
                  </a>
                </li>
              </template>
            </ul>
          </div>
        </div>
        <div class="wrapper">
          <ul class="list">
              <li class="item" v-if="$parent.footerSupportEmail">
                <div class="title">{{$t('Support:')}}</div>
                <div class="desc">{{$parent.footerSupportEmail}}</div>
              </li>
          </ul>
          <ul class="nav">
            <li v-for="link in socialLinks" :key="link.image" class="nav__item nav__item-text-pages">
              <a :href="link.link" class="desc" target="_blank">
                <img :src="link.img" :alt="link.url" class="img" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="copyright-section">
        <div class="wrapper">
          <div class="desc" v-if="$parent.footerCopyright">{{$parent.footerCopyright}}</div>
          <ul class="list">
            <li class="item">
              <img src="./../assets/apple-pay.svg" class="img applepay"/>              
            </li>
            <li class="item">
              <img src="./../assets/google-pay.svg" class="img googlepay"/>
            </li>
            <li class="item">
              <img src="./../assets/mc_symbol.svg" class="img"/>
            </li>
            <li class="item">
              <img src="./../assets/visa.svg" class="img"/>
            </li>
            <li class="item">
              <img src="./../assets/3ds.png" class="img"/>
            </li>
          </ul>
        </div>
      </div>
    </footer>
</template>

<script>

export default {
	name: 'Footer',
	props: [],
	data: function() {
		return {
      socialLinks: []
		}
	},
	methods: {
    getSocialLinks() {
      this.$http.get(process.env.VUE_APP_API + 'social-links')
      .then(({ data }) => {
        this.socialLinks = data.payload;
      })
    }
	},
	mounted() {
    this.getSocialLinks();
  }
}
</script>
