<template>
	<header class="header">
        <div class="wrapper">
          <router-link to="/" class="logo">
            <img src="./../assets/logo.svg" class="img"/>
            <img src="./../assets/logo-mini.svg" class="img mob"/>
          </router-link>
          <div class="right">
            <LanguageSelectDropdown />
            <ul class="nav">
              <template v-for="(item, i) in $parent.nav">
                <li class="nav__item" :key="i">
                  <router-link :to="item.link">
                    <span v-if="item.title == 'New Arrivals'">{{ $t('New') }}</span> 
                    <template v-if="item.title == 'New Arrivals'">
                      {{ $t('Arrivals') }}
                    </template>
                    <template v-else>
                      {{$t(item.title) != $t('Sale')? $t(item.title) : ''}}
                    </template>
                    <span class="red" v-if="item.title == 'Sale'">{{ $t('Sale') }}</span>
                  </router-link>
                </li>
              </template>
              <!-- <div class="nav__item" v-if="$route.name == 'Profile'  || $route.name == 'SellOffers'">
                <a @click="$emit('openSaleSkinModal')">Sale your skin</a>
              </div> -->
            </ul>
            <router-link to="/cart" v-if="$parent.isAuth" class="icon">
              <svg id="shopping-cart_1_" data-name="shopping-cart (1)" xmlns="http://www.w3.org/2000/svg" width="19.367" height="18.119" viewBox="0 0 19.367 18.119">
                <path id="Контур_3" data-name="Контур 3" d="M50.367,94.873H35.607L34.745,91H31v1.291h2.71l2.462,11.125h-.008a3.109,3.109,0,0,0,1.607,2.712,1.942,1.942,0,1,0,3.439.408h4.111a1.919,1.919,0,0,0-.119.646,1.937,1.937,0,1,0,1.937-1.937H39.285a1.829,1.829,0,0,1-1.778-1.417l12.861-2.573Z" transform="translate(-31 -91)" fill="#8d8e92"/>
              </svg>
              <div class="indicator">{{cartContents.length}}</div>
            </router-link>
            <div class="link-container dropdown-open" v-if="$parent.isAuth">
              <div class="icon">
                <svg id="avatar" xmlns="http://www.w3.org/2000/svg" width="13.721" height="17.38" viewBox="0 0 13.721 17.38">
                  <g id="Сгруппировать_2" data-name="Сгруппировать 2" transform="translate(2.973 0)">
                    <g id="Сгруппировать_1" data-name="Сгруппировать 1">
                      <ellipse id="Эллипс_1" data-name="Эллипс 1" cx="3.888" cy="3.888" rx="3.888" ry="3.888" fill="#8d8e92"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_4" data-name="Сгруппировать 4" transform="translate(0 8.69)">
                    <g id="Сгруппировать_3" data-name="Сгруппировать 3">
                      <path id="Контур_1" data-name="Контур 1" d="M60.755,256a6.86,6.86,0,0,0-6.86,6.86,1.829,1.829,0,0,0,1.829,1.829H65.786a1.829,1.829,0,0,0,1.829-1.829A6.861,6.861,0,0,0,60.755,256Z" transform="translate(-53.895 -256)" fill="#8d8e92"/>
                    </g>
                  </g>
                </svg>
              </div>
              <div class="dropdown">
                <div class="nav__item">
                  <router-link to="/profile">
                    {{ $t('Overview') }}
                  </router-link>
                </div>
                <!-- <div class="nav__item">
                  <router-link to="sell-offers">
                    Sell Offers
                  </router-link>
                </div> -->
                <div class="nav__item nav__item-logout">
                  <a @click="logout">
                    {{ $t('Log Out') }}
                  </a>
                </div>
              </div>
            </div>
            <div class="link-container modal-open" id="signInBtn" @click="$emit('openSignInModal')" v-else>
              <div class="icon">
                <svg id="avatar" xmlns="http://www.w3.org/2000/svg" width="13.721" height="17.38" viewBox="0 0 13.721 17.38">
                  <g id="Сгруппировать_2" data-name="Сгруппировать 2" transform="translate(2.973 0)">
                    <g id="Сгруппировать_1" data-name="Сгруппировать 1">
                      <ellipse id="Эллипс_1" data-name="Эллипс 1" cx="3.888" cy="3.888" rx="3.888" ry="3.888" fill="#8d8e92"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_4" data-name="Сгруппировать 4" transform="translate(0 8.69)">
                    <g id="Сгруппировать_3" data-name="Сгруппировать 3">
                      <path id="Контур_1" data-name="Контур 1" d="M60.755,256a6.86,6.86,0,0,0-6.86,6.86,1.829,1.829,0,0,0,1.829,1.829H65.786a1.829,1.829,0,0,0,1.829-1.829A6.861,6.861,0,0,0,60.755,256Z" transform="translate(-53.895 -256)" fill="#8d8e92"/>
                    </g>
                  </g>
                </svg>
              </div>
              <div class="link">{{ $t('Sign In') }}</div>
              <div class="divider">/</div>
              <div class="link">{{ $t('Sign Up') }}</div>
            </div>
          </div>
        </div>
    </header>
</template>

<script>
import LanguageSelectDropdown from '@/components/LanguageSelectDropdown.vue'

export default {
	name: 'Header',
  props: ['cartContents'],
	data: function() {
		return {
		}
	},
  components: {
    LanguageSelectDropdown
  },
	methods: {
    openSignInModal() {
      this.$emit('openSignInModal')
    },
    openSaleSkinModal() {
      this.$emit('openSaleSkinModal')
    },
    logout() {
      this.$emit('logout')
    }
	}
}
</script>
